<template>
    <div class="page">
        <div class="d-flex">
            <div class="banner">
                <img
                    src="https://ipb.link/maintenancegenbi"
                    alt="maintenance"
                    class="banner"
                />
                <!-- <a href="https://lh3.googleusercontent.com/AlLeML0qzWzgAtv5TBz5JdnKTOHzFykiyInR79rYo4L8MoY9lBhWSnsnpFp6QgjSpIegLRImgPnx_Xt0jaEjMQYcufV1INf7JPdaGyytx6qLstK5h8mqLgkoVLI77ItIyrboYJy3=w2400?source=screenshot.guru"> <img src="https://lh3.googleusercontent.com/AlLeML0qzWzgAtv5TBz5JdnKTOHzFykiyInR79rYo4L8MoY9lBhWSnsnpFp6QgjSpIegLRImgPnx_Xt0jaEjMQYcufV1INf7JPdaGyytx6qLstK5h8mqLgkoVLI77ItIyrboYJy3=w600-h315-p-k" /> </a> -->
            </div>
            <div class="content">
                <h1 class="title">
                    Selamat datang di website GenBI IPB
                </h1>
                <p>
                    Mohon maaf saat ini website GenBI IPB sedang dalam
                    pengembangan, silahkan kembali dalam waktu yang dekat
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.page {
    height: 98vh;
    padding: 0 90px;
}
.content {
    text-align: center;
    max-width: 600px;
}
.d-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
}
h1 {
    margin: 0px;
}
p {
    line-height: 1.8;
}
.title {
    // flex-grow: 1;
}
.banner {
    // flex-grow: 1;
    max-width: 500px;
}
</style>
